html {
  background-color: #000;
  overflow-y: auto
}

body {
  color: #eff0f1;
  animation: fadeInOpacity 0.5s
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

a {
  color: #209cee
}

a:hover {
  color: #67c3ff
}

hr {
  background-color: #585858
}

code,
.message-body code {
  background-color: #000;
  border-radius: 5px;
  font-size: 1rem
}

.subtitle {
  color: #bdc3c7
}

.subtitle strong {
  color: #bdc3c7
}

.title,
.subtitle.is-brighter,
.subtitle.is-brighter strong {
  color: #eff0f1
}

.input,
.select select,
.textarea {
  color: #eff0f1;
  border-color: #585858;
  background-color: #000
}

.input::-moz-placeholder,
.textarea::-moz-placeholder {
  color: #bdc3c7
}

.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder {
  color: #bdc3c7
}

.input:-moz-placeholder,
.textarea:-moz-placeholder {
  color: #bdc3c7
}

.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: #bdc3c7
}

.input:not([disabled]):hover,
.select fieldset:not([disabled]) select:hover,
.select select:not([disabled]):hover,
.textarea:not([disabled]):hover,
fieldset:not([disabled]) .input:hover,
fieldset:not([disabled]) .select select:hover,
fieldset:not([disabled]) .textarea:hover {
  border-color: #209cee
}

.input.is-active,
.input.is-focused,
.input:active,
.input:focus,
.textarea.is-active,
.textarea.is-focused,
.textarea:active,
.textarea:focus {
  border-color: #209cee
}

.input[disabled],
.select fieldset[disabled] select,
.select select[disabled],
.textarea[disabled],
fieldset[disabled] .input,
fieldset[disabled] .select select,
fieldset[disabled] .textarea {
  border-color: #585858;
  background-color: #2f2f2f
}

.label {
  color: #eff0f1;
  font-weight: normal
}

.help {
  color: #bdc3c7
}

.progress {
  background-color: #585858
}

.button.is-info.is-hovered [class^="icon-"]::before,
.button.is-info.is-hovered [class*=" icon-"]::before,
.button.is-info:hover [class^="icon-"]::before,
.button.is-info:hover [class*=" icon-"]::before {
  fill: #fff
}

.button.is-wrappable {
  white-space: break-spaces;
  min-height: 2.25em;
  height: auto
}

.checkbox:hover,
.radio:hover {
  color: #7f8c8d
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #eff0f1
}

.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: #eff0f1
}

.select select[disabled]:hover,
fieldset[disabled] .select select:hover {
  border-color: #585858
}

.message {
  background-color: #2f2f2f
}

.message-body {
  color: #eff0f1;
  border: 0
}

.table {
  color: #bdc3c7;
  background-color: #000
}

.table.is-narrow {
  font-size: 0.75rem
}

.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #2f2f2f
}

.table td,
.table th {
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px solid #585858
}

.table th {
  color: #eff0f1;
  height: 2.25em;
  font-weight: normal
}

.table th.capitalize {
  text-transform: capitalize
}

.table thead td,
.table thead th {
  color: #eff0f1;
  background-color: #383838;
  border-bottom: 0;
  height: 31px
}

.table tbody tr:last-child td,
.table tbody tr:last-child th {
  border-bottom-width: 1px
}

.table .cell-indent {
  padding-left: 2.25em
}

/** Cookie Consent **/
.cc-window {
  font-family: inherit !important
}

.cc-link {
  padding: 0 !important
}

/* floating button's bottom offset + height + bottom offset */
.section.has-extra-bottom-padding {
  padding-bottom: 6.5rem
}

a.floating-home-button {
  display: flex;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  border-radius: 100%;
  background-color: #209cee;
  color: white;
  width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s
}

a.floating-home-button:hover {
  background-color: #67c3ff;
  color: white
}

a.floating-home-button > .icon {
  margin-top: -2px
}

/* https://github.com/philipwalton/flexbugs#flexbug-3 */
.hero.is-fullheight > .hero-body {
  min-height: 100vh;
  height: 100%
}

/* https://github.com/philipwalton/flexbugs#flexbug-2 */
.hero.is-fullheight > .hero-body > .container {
  width: 100%
}
